import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SvgIcon, Typography } from '@mui/material';

export type ModalProps = PropsWithChildren<{
  className?: string;
  onRequestClose?: () => void;
  open: boolean;
  title?: ReactNode;
  width?: string;
  maxHeight?: string;
}>;

export const Modal: FC<ModalProps> = ({ children, className, onRequestClose, open, title, width = '660px', maxHeight = '430px' }) => {
  const styles = useMemo(
    () => ({
      overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10000,
      },
    }),
    []
  );

  return (
    <StyledGlobalModalStyles
      className={className}
      max_height={maxHeight}
      width={width}
      style={styles}
      isOpen={open}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
    >
      <ModalContent>
        {onRequestClose && (
          <CloseButton onClick={onRequestClose}>
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </CloseButton>
        )}
        {title && (
          <ModalHeader>
            <Typography variant={'h5'}>{title}</Typography>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </StyledGlobalModalStyles>
  );
};

const StyledGlobalModalStyles = styled(ReactModal) <{ width: string, max_height: string }>`
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  outline: none;
  background: ${(p) => p.theme.palette.background.default};
  overflow: auto;
  position: absolute;
  width: ${(p) => p.width};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: ${(p) => p.max_height};

  .ReactModal__Overlay {
    z-index: 10000;
  }

`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ModalBody = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 24px;
`;

export const ModalHeader = styled.header`
  border-bottom: 1px solid #e0e0e0;
  padding: 24px;
`;

export const CloseButton = styled(IconButton)`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`;
