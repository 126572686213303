import { CircularProgress } from '@mui/material';
import { DataReportUploadFileDefinition, usePpDownloadCsvMutation } from 'graphql/types';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { FC, useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse'
import { useMuiNotifications } from 'context/muiNotificationContext';
import { green } from '@mui/material/colors';
import { useAppSelector } from 'store/hooks';
import { Modal } from 'components/modal/modal';
import styled from 'styled-components';

export type DataViewerDialogProps = {
  open: boolean;
  partner: DataReportUploadFileDefinition;
  onClose: () => void;
};

export const DataViewerDialog: FC<DataViewerDialogProps> = ({ open, onClose, partner }) => {
  const { createFailureNotification } = useMuiNotifications();
  const [isLoading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);

  const partnerId = useAppSelector(state => state.app.partnerId);
  const [downloadCsvMutation] = usePpDownloadCsvMutation();

  const getSignedCsvUrl = useCallback(() => {
    const loadDataFromSignedUrl = (url: string) => {
      Papa.parse(url, {
        header: true,
        download: true,
        skipEmptyLines: true,
        delimiter: ',',
        complete: (results) => {
          setLoading(false);
          const rows = results.data as any[];
          setCsvData(rows);
        },
        error(error) {
          setLoading(false);
          createFailureNotification(`Failed: ${error.message}`);
        },
      })
    };

    if (partner?.reportType && partnerId) {
      setLoading(true);
      downloadCsvMutation({
        variables: {
          partnerId: partnerId,
          reportType: partner?.reportType
        },
        onCompleted(data) {
          if (data.generateDownloadFileUrlData.signedS3URL) {
            const url = data.generateDownloadFileUrlData.signedS3URL;
            loadDataFromSignedUrl(url);
          } else {
            setLoading(false);
            createFailureNotification('Cannot fetch uploaded data');
          }
        },
        onError(error) {
          setLoading(false);
          createFailureNotification(`Failed: ${error.message}`);
        },
      });
    }
  }, [partner, partnerId, createFailureNotification, downloadCsvMutation]);

  useEffect(() => {
    if (open) {
      getSignedCsvUrl();
    }
  }, [open, getSignedCsvUrl]);

  const handleClose = () => {
    onClose();
  };

  const columns = partner.fields.map(field => {
    return {
      name: field.name,
      options: {
        filter: true,
        sort: true,
      }
    };
  });

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      body: {
        noMatch: isLoading || !open ? '' : 'Empty data',
      }
    }
  };

  return (
    <Modal
      onRequestClose={handleClose}
      maxHeight={'90%'}
      width='70%'
      open={open}
      title={`DATA VIEWER ${partner.reportType} [${partner.tableName}]`}>
      <StyledContainer>
        <MUIDataTable title={''} columns={columns} data={csvData} options={options} />
        {isLoading && (
          <CircularProgress
            size={40}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        )}
      </StyledContainer>
    </Modal>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;