import { createBrowserRouter } from 'react-router-dom';

import { AppLayout } from 'AppLayout';
import { ConnectAccountView } from 'components/connectAccountView/ConnectAccountView';
import { DashboardView } from 'components/dashboardView/DashboardView';
import { Logout } from 'components/logout/Logout';
import { NodeView } from 'components/nodeView/NodeView';
import { ManageYourData } from 'components/manageYourData/ManageYourData';
import { LWACallback } from 'components/lwaCallback/LWACallback';
import { HelpWrapView } from 'components/help/HelpWrapView';
import { AccountWrapView } from 'components/account/AccountWrapView';
import { ReportView } from 'components/dashboardView/ReportView';
import { DatabaseCredentials } from 'components/databaseCredentials/databaseCredentials';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <DashboardView />,
      },
      {
        path: '/connect/callback',
        element: <LWACallback />,
      },
      {
        path: '/connect/*',
        element: <ConnectAccountView />,
      },
      {
        path: '/data/*',
        element: <ManageYourData />,
      },
      {
        path: '/data/:property',
        element: <NodeView />,
      },
      {
        path: '/data/credentials',
        element: <DatabaseCredentials />,
      },
      {
        path: '/account/:property',
        element: <AccountWrapView />,
      },
      {
        path: '/help/:property',
        element: <HelpWrapView />,
      },
      {
        path: '/reports/:groupId/:id',
        element: <ReportView />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
    ],
  },
]);
