import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type AppState = {
  partnerId: string | null; // if there are one more partner provided, this value would be partner id selected thing by user.
  partnerName: string | null; // if there are one more partner provided, this value would be partner name selected thing by user.
  partnerUUUID: string | null;
  reloadManageNodes: boolean; // Set true if need to reload `listNodesData` anywhere, once it's called, then set false.
  selectedNodeData: string | null;
};

export const initialState: AppState = {
  partnerId: null,
  partnerName: null,
  partnerUUUID: null,
  reloadManageNodes: false,
  selectedNodeData: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPartnerId(state, action: PayloadAction<string | null>) {
      state.partnerId = action.payload;
    },
    setPartnerName(state, action: PayloadAction<string | null>) {
      state.partnerName = action.payload;
    },
    setPartnerUUID(state, action: PayloadAction<string | null>) {
      state.partnerUUUID = action.payload;
    },
    setReloadManageNodes(state, action: PayloadAction<boolean>) {
      state.reloadManageNodes = action.payload;
    },
    setSelectedNodeData(state, action: PayloadAction<string | null>) {
      state.selectedNodeData = action.payload;
    },
  },
});

export const { actions, name } = appSlice;
export default appSlice.reducer;
