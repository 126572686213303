import { ApolloError, gql } from '@apollo/client';
import Button from '@mui/material/Button';
import { Formik, Form } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import { object, string } from 'yup';

import { useConnectAccountContext } from 'context/connectAccountContext';
import { useMarketplaces } from 'hooks/useMarketplaces';
import { ConnectAccountFormFields } from './ConnectAccountFormFields';
import { ConnectAccountFormState } from './common';
import { Marketplace, PublicPartnersDocument, useCreatePartnerMutation } from 'graphql/types';
import { useMuiNotifications } from 'context/muiNotificationContext';

export type ConnectAccountFormProps = {
  onSubmit?: (values: ConnectAccountFormState) => void | Promise<void>;
};

export const ConnectAccountForm: FC<ConnectAccountFormProps> = ({ onSubmit }) => {
  const { setState, ...initialValues } = useConnectAccountContext();
  const { marketplaceByProduct } = useMarketplaces();
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();

  const [createPartner] = useCreatePartnerMutation({
    refetchQueries: [{ query: PublicPartnersDocument }],
  });

  return (
    <Formik<ConnectAccountFormState>
      validationSchema={YUP_VALIDATION_SCHEMA}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await createPartner({
            variables: {
              partner: {
                marketplace: values.marketplace as Marketplace,
                name: values.brandName,
                type: values.accountType,
              },
            },
          });

          createSuccessNotification('Successfully created partner');

          setState((oldState) => {
            return {
              ...oldState,
              ...values,
              brandId: response.data?.createPublicPartner?.id || '',
              isExistingBrand: true,
            };
          });

          await onSubmit?.(values);
        } catch (e) {
          const error = e as ApolloError;

          createFailureNotification(`Failed to create partner: ${error.message}`);

          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <StyledFormBox noValidate>
            <ConnectAccountFormFields marketplaceByProduct={marketplaceByProduct} />
            <StyledButtonContainer>
              <Button disabled={isSubmitting} variant={'contained'} type="submit">
                Continue
              </Button>
            </StyledButtonContainer>
          </StyledFormBox>
        );
      }}
    </Formik>
  );
};

const YUP_VALIDATION_SCHEMA = object({
  brandName: string().required('Required'),
  marketplace: string().required('Required'),
  accountType: string().required('Required'),
});

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const StyledFormBox = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

export const CreatePublicPartnerMutation = gql`
  mutation CreatePartner($partner: PublicPartnerInput!) {
    createPublicPartner(partner: $partner) {
      ...PublicPartnerFragment
    }
  }
`;

export const UpdatePublicPartnerMutation = gql`
  mutation UpdatePartner($partner: PublicPartnerInput!, $partnerId: String!) {
    updatePublicPartner(partner: $partner, partnerId: $partnerId) {
      ...PublicPartnerFragment
    }
  }
`;
