import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

export const AccountBillingView: FC = () => {
  const billingLink = 'https://billing.stripe.com/p/login/8wM6pl9q985a9Pi288';

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <StyledContainer>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}>
        Billing Information
      </Typography>
      <StyledNormalTypography
        textAlign={'center'}>
        The button below will redirect you to Reason Automation's payment processor, Stripe.
      </StyledNormalTypography>
      <StyledNormalTypography
        textAlign={'center'}>
        Please log in using your billing email address.
      </StyledNormalTypography>

      <Button
        sx={{
          width: '240px',
          height: '46px',
          backgroundColor: '#e1e1e1',
          color: '#000000',
          fontWeight: 600,
          textTransform: 'none',
          mt: '70px',
        }}
        onClick={() => {
          openInNewTab(billingLink);
        }}>
        Manage billing in Stripe
      </Button>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: '#000000',
});