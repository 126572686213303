import { Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { HubspotContactForm } from './parts/HubspotContactForm';

export const HelpReportProblemView: FC = () => {
  return (
    <HubspotProvider>
      <StyledContainer>
        <Typography
          textAlign={'center'}
          sx={{
            fontSize: 30,
            fontWeight: 800,
            color: '#000000',
          }}>
          Report a problem
        </Typography>
        <StyledNormalTypography
          sx={{ mt: '24px' }}
          textAlign={'center'}>
          Please use the form below to submit a support request. Our team will respond within 1 business day.
        </StyledNormalTypography>
        <StyledNormalTypography
          textAlign={'center'}>
          Our business hours are 09:00 - 18:00 Pacific Time, Monday - Friday.
        </StyledNormalTypography>
        <HubspotContactForm
          portalId={process.env.REACT_APP_HBSPT_PORTAL_ID ?? ''}
          formId={process.env.REACT_APP_HBSPT_FORM_ID ?? ''}
        />
      </StyledContainer>
    </HubspotProvider>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: '#000000',
});