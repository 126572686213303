import { Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { Report } from 'powerbi-client';
import { LineProgressBar } from 'components/progressbar/LineProgressBar';
import { PowerBiReportExportFormat, usePbiExportMutation, usePbiExportRefreshStatusMutation } from 'graphql/types';
import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

export type PowerBIDownloadModalProps = {
  report?: Report;
  reportId?: string;
  groupId?: string;
  open: boolean;
  format?: PowerBiReportExportFormat;
  onRequestClose?: () => void;
};

export const PowerBIDownloadModal: FC<PowerBIDownloadModalProps> = ({
  report,
  reportId,
  groupId,
  format,
  open,
  onRequestClose,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [exportId, setExportId] = useState<string | null>(null);
  const [isDownloading, setDownloading] = useState(false);

  const [getPbiExport] = usePbiExportMutation();
  const [getPbiExportRefreshStatus] = usePbiExportRefreshStatusMutation();

  const downloadFile = useCallback(
    async (downloadUrl: string) => {
      setDownloading(true);

      try {
        const response = await fetch(downloadUrl, {
          method: 'GET',
        });

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${reportId ?? ''}.${format === PowerBiReportExportFormat.Pptx ? 'pptx' : 'pdf'}`;
        a.click();

        setExportId(null);
        setProgress(0);
        setErrorMsg('');
        setDownloading(false);

        onRequestClose?.();

        return true;
      } catch (e) {
        setDownloading(false);
        setErrorMsg('Failed to download file.');

        return false;
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [format, onRequestClose, reportId]
  );

  const fetchPbiExport = useCallback(async () => {
    if (!reportId || !groupId || !format || exportId || !report) {
      return;
    }
    try {
      setProgress(0);
      const bookmark = await report.bookmarksManager.capture({
        allPages: true,
        personalizeVisuals: true,
      });
      const pbiRes = await getPbiExport({
        variables: {
          reportId: reportId,
          groupId: groupId,
          format: format,
          bookmarkState: bookmark.state,
        },
      });

      if (exportId) {
        return;
      }

      if (pbiRes && pbiRes.data?.exportPowerBIReport?.exportId) {
        setExportId(pbiRes.data?.exportPowerBIReport?.exportId);
        setProgress(1);
      } else {
        setErrorMsg('Failed to download file');
      }
    } catch {
      setErrorMsg('Failed to download file');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, groupId, format, exportId]);

  const fetchPbiExportStatus = useCallback(
    async (exportId: string, retryAttempt = 0) => {
      if (!reportId || !groupId || !format || !exportId) {
        return;
      }
      try {
        const refreshStatusRes = await getPbiExportRefreshStatus({
          variables: {
            reportId: reportId,
            groupId: groupId,
            format: format,
            exportId: exportId,
          },
        });

        if (refreshStatusRes.data?.refreshPowerBIReportExportStatus) {
          const status = refreshStatusRes.data?.refreshPowerBIReportExportStatus;

          if (status.exportCompletionPercentage > 1) {
            setProgress(status.exportCompletionPercentage);
          }
          if (status.downloadUrl) {
            setProgress(100);
            const downloadPassed = await downloadFile(status.downloadUrl);

            if (!downloadPassed && retryAttempt < 3) {
              setErrorMsg('');
              setTimeout(() => {
                fetchPbiExportStatus(exportId, retryAttempt + 1);
              }, 250);
            }
          } else {
            setTimeout(() => {
              fetchPbiExportStatus(exportId);
            }, 2000);
          }
        } else {
          setErrorMsg('Failed to download file');
        }
      } catch {
        setErrorMsg('Failed to download file');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [reportId, groupId, format, downloadFile, getPbiExportRefreshStatus]
  );

  useEffect(() => {
    if (open) {
      fetchPbiExport();
    }
    return () => {
      setExportId(null);
      setProgress(0);
      setErrorMsg('');
      setDownloading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!!exportId && progress === 1) {
      fetchPbiExportStatus(exportId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportId, progress]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      open={open}
      title={'Downloading...'}>
      <StyledContainer>
        <LineProgressBar value={progress} />
        <Typography color={errorMsg.length > 0 ? 'error' : 'primary'}>
          {errorMsg.length > 0 ? errorMsg : isDownloading ? 'Starting download...' : 'Please wait...'}
        </Typography>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;
