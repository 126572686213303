import { FC, useState } from 'react';
import { Embed, models, Report } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { Button } from '@mui/material';
import '../../../assets/css/fontawesome.css';
import '../../../assets/css/brands.css';
import '../../../assets/css/solid.css';
import { PowerBiReportExportFormat } from 'graphql/types';
import { PowerBIDownloadModal } from './pbi/PowerBIDownloadModal';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';

export type PowerBIReportViewProps = {
  reportId?: string;
  groupId?: string;
  embedUrl: string | undefined;
  accessToken: string | undefined;
};

export const PowerBIReportView: FC<PowerBIReportViewProps> = ({ embedUrl, accessToken, reportId, groupId }) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState<PowerBiReportExportFormat | undefined>(undefined);
  const [report, setReport] = useState<Report | undefined>(undefined);
  const reportClass = 'report-container';
  const reportConfig = {
    type: 'report',
    embedUrl: embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken: accessToken,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: true,
        },
        pageNavigation: {
          visible: true,
        },
        selection: {
          visible: true,
        },
        syncSlicers: {
          visible: true,
        },
        visualizations: {
          expanded: false,
        },
      },
    },
  };

  return (
    <StyledContainer>
      {loaded && (
        <StyledTopContainer className="report-container-top">
          <StyledButton
            variant="text"
            onClick={() => {
              trackGoogleAnalyticsClickEvent('Download PPTX clicked', 'PowerBI Report');
              setSelectedExportType(PowerBiReportExportFormat.Pptx);
            }}
          >
            Download PPTX
          </StyledButton>
          <StyledButton
            variant="text"
            onClick={() => {
              trackGoogleAnalyticsClickEvent('Download PDF clicked', 'PowerBI Report');
              setSelectedExportType(PowerBiReportExportFormat.Pdf);
            }}
          >
            Download PDF
          </StyledButton>
        </StyledTopContainer>
      )}
      <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName={reportClass}
        getEmbeddedComponent={(embedObject: Embed) => {
          setReport(embedObject as Report);
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                setLoaded(true);
              },
            ],
            [
              'rendered',
              function () {
                setLoaded(true);
              },
            ],
            [
              'error',
              function (event) {
                setLoaded(false);
              },
            ],
          ])
        }
      />
      <PowerBIDownloadModal
        report={report}
        open={!!selectedExportType}
        reportId={reportId}
        groupId={groupId}
        format={selectedExportType}
        onRequestClose={() => setSelectedExportType(undefined)}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .report-container-top {
    height: 38px;
  }
  .report-container {
    height: calc(100% - 40px);
  }
  flex-direction: column;
`;

const StyledTopContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  gap: 8px;
`;

export const PBIMutations = gql`
  mutation PbiExport($reportId: String!, $groupId: String!, $format: PowerBIReportExportFormat!, $bookmarkState: String) {
    exportPowerBIReport(reportId: $reportId, groupId: $groupId, format: $format, bookmarkState: $bookmarkState) {
      exportId
    }
  }

  mutation PbiExportRefreshStatus(
    $reportId: String!
    $groupId: String!
    $format: PowerBIReportExportFormat!
    $exportId: String!
  ) {
    refreshPowerBIReportExportStatus(reportId: $reportId, groupId: $groupId, format: $format, exportId: $exportId) {
      status
      exportCompletionPercentage
      downloadUrl
      reportName
    }
  }
`;

const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  textTransform: 'none',
});
