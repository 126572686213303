import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountBillingView } from './AccountBillingView';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';

export const AccountWrapView: FC = () => {
  const { property } = useParams<{ property: string }>();

  useEffect(() => {
    if (property) {
      trackGoogleAnalyticsPageView(`/account/${property}`, 'Billing Admin');
    }
  }, [property]);

  const getChildren = () => {
    if (!!property && property === 'billing') {
      return <AccountBillingView />
    }
    return null;
  };

  return (
    <StyledContainer>
      {getChildren()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
`;