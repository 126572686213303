import { PublicPartnerCredentialsSnowflake } from 'graphql/types';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { FC, useMemo } from 'react';

export type SnowflakeDatabaseProps = {
  data: PublicPartnerCredentialsSnowflake[];
};
export const SnowflakeDatabase: FC<SnowflakeDatabaseProps> = ({ data }) => {
  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
  };

  const columns = useMemo(() => {
    const columns = [
      {
        label: 'Message',
        name: 'message',
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
    return columns;
  }, []);

  return <MUIDataTable title='SNOWFLAKE' columns={columns} data={data} options={options} />;
};