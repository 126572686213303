import { ChangeEventHandler, FC, useState } from 'react';
import { DataReportType, DataReportUploadFileDefinition, DataReportUploadFileFieldDefinition, usePpUploadCsvMutation } from 'graphql/types';
import { Button, Stack, Typography, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { gql } from '@apollo/client';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { actions } from 'store/reducers/app';
import Papa from 'papaparse'
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';

export type FileUploadButtonProps = {
  reportType?: DataReportType | null;
  partner: DataReportUploadFileDefinition | null;
  setUploading?: (uploading: boolean) => void;
  fileUploaded: (reportType?: DataReportType | null) => void;
};

export const FileUploadButton: FC<FileUploadButtonProps> = ({ reportType, partner, setUploading, fileUploaded }) => {
  const dispatch = useAppDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [openExtraFieldsFile, setOpenExtraFieldsFile] = useState<File | undefined>(undefined);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertTitle, setAlertTitle] = useState<string | undefined>(undefined);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    trackGoogleAnalyticsClickEvent('File upload clicked', 'Manage your data');
    if (event.currentTarget.files && (event.currentTarget.files || []).length > 0) {
      validateCsv(event.currentTarget.files[0]);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    accept: { 'text/csv': [] },
    maxFiles: 1,
    onDrop(acceptedFiles) {
      if (acceptedFiles.length > 0) {
        validateCsv(acceptedFiles[0]);
      }
    },
  });

  const { createSuccessNotification } = useMuiNotifications();
  const [uploadCsvMutation] = usePpUploadCsvMutation();
  const partnerId = useAppSelector((state) => state.app.partnerId);

  const checkUniqueRow = (row: any, rowIndex: number, uniqueFieldNames: string[], hashMap: Map<string, number>) => {
    const fields = uniqueFieldNames.map(name => row[name] || '');
    const uniqueKey = fields.join('##');
    const existingRow = hashMap.get(uniqueKey);

    if (existingRow !== undefined) {
      let message = '';
      const columns = uniqueFieldNames.map(name => `"${name}"`);
      const columnMsg = columns.join(' and ');
      if (uniqueFieldNames.length > 1) {
        message = `Row ${rowIndex + 1}: The value of columns ${columnMsg} is not unique and conflicts with Row ${existingRow + 1}.`;
      } else {
        message = `Row ${rowIndex + 1}: The value of column ${columnMsg} is not unique and conflicts with Row ${existingRow + 1}.`;
      }
      message += '\n';
      uniqueFieldNames.forEach(name => {
        message += `\n"${name}": "${row[name]}"`;
      });
      message += '\n\n';
      if (uniqueFieldNames.length > 1) {
        message += `Make sure all rows have unique values for the combination of ${columnMsg} columns.`;
      } else {
        message += `Make sure all rows have a unique value for the ${columnMsg} column.`;
      }

      return {
        success: false,
        message: message,
      };
    }

    return {
      success: true,
      uniqueKey: uniqueKey,
      uniqueValue: rowIndex,
    };
  };

  const validateRow = (file: File, row: any, rowIndex: number, fields: DataReportUploadFileFieldDefinition[]) => {
    if (Object.keys(row).length > fields.length) {
      const extraFields: string[] = [];
      const fieldNames = fields.map((f) => f.name);
      Object.keys(row).forEach((row) => {
        if (!fieldNames.includes(row)) {
          extraFields.push(row);
        }
      });

      setAlertMessage(`The following fields have been detected in the CSV file. They will be ignored. \n${extraFields.toString()}`);
      setAlertTitle('UPLOAD FILE CONFIRMATION');
      setOpenExtraFieldsFile(file);
      setOpenAlert(true);

      return {
        invalidMsg: undefined,
        isValid: false,
      }
    } else {
      const missingFields: string[] = [];
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (!row[field.name] && field.regex) {
          missingFields.push(field.name);
        }
      }

      if (missingFields.length > 0) {
        return {
          invalidMsg: `Row ${rowIndex + 1}: has missing columns: (${missingFields.join(', ')})`,
          isValid: false,
        }
      }

      let inValidMsg = undefined;
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (field.regex) {
          const validField = new RegExp(field.regex);
          if (!row[field.name] || !validField.test(row[field.name])) {
            inValidMsg = `Row ${rowIndex + 1}: Column ${field.name}: invalid value "${row[field.name]}"`;
            if (field.regexErrorMessage) {
              inValidMsg += '\n' + field.regexErrorMessage;
            }
            break;
          }
        }
      }

      return {
        invalidMsg: inValidMsg,
        isValid: inValidMsg === undefined,
      }
    }
  };

  const validateCsv = (file: File) => {
    Papa.parse(file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter: ',',
      complete: (results) => {
        const rows = results.data as any[];
        if (rows.length > 0) {
          const uniqueFields = new Map<string, number>();
          let isValid = true;

          if (partner?.fields) {
            for (let i = 0; i < rows.length; i++) {
              const validRes = validateRow(file, rows[i], i, partner.fields);

              if (validRes.invalidMsg) {
                showAlertDialog('Unable to upload due to validation failure', validRes.invalidMsg);
                isValid = false;
                break;
              } else if (!validRes.isValid) {
                isValid = false;
                break;
              } else {
                // Check unique fields
                if (partner.uniqueFields.length > 0 && partner.uniqueFields[0].fieldNames.length > 0) {
                  const res = checkUniqueRow(rows[i], i, partner.uniqueFields[0].fieldNames, uniqueFields);
                  if (res.success) {
                    if (res.uniqueKey) {
                      uniqueFields.set(res.uniqueKey, res.uniqueValue);
                    }
                  } else {
                    showAlertDialog('Unable to upload due to validation failure', res.message);
                    isValid = false;
                    break;
                  }
                }
              }
            }
          }

          if (isValid) {
            startUploadCsvProcess(file);
          }
        } else {
          showAlertDialog('Unable to upload due to validation failure', 'Invalid CSV File.');
        }
      },
    })
  };

  const showAlertDialog = (title: string, message: string | undefined) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setOpenAlert(true);
  };

  const startUploadCsvProcess = (file: File) => {
    if (!partnerId || !reportType) {
      return;
    }

    setUploading?.(true);
    uploadCsvMutation({
      variables: {
        partnerId: partnerId,
        reportType: reportType,
      },
      onCompleted(data) {
        if (data.generateUploadFileUrlData.signedS3URL) {
          const signedUrl = data.generateUploadFileUrlData.signedS3URL;
          uploadCsvToSignedUrl(signedUrl, file);
        } else {
          setUploading?.(false);
          showAlertDialog('Error', 'Failed to get a signed uploading url');
        }
      },
      onError(error) {
        setUploading?.(false);
        showAlertDialog('Error', `Failed: ${error.message}`);
      },
    });
  };

  const uploadCsvToSignedUrl = async (signedUrl: string, file: File) => {
    // ref: https://stackoverflow.com/a/59541675
    // ref2: https://allardqjy.medium.com/using-pre-signed-urls-to-upload-files-to-amazon-s3-from-reactjs-5b15c94b66df
    if (!file?.size) {
      showAlertDialog('Error', 'No data present. Please select another file.');
      setUploading?.(false);
      return;
    }

    fetch(signedUrl, {
      method: 'PUT',
      body: file,
    })
      .then((response) => {
        // handle the response
        setUploading?.(false);
        if (response.ok) {
          createSuccessNotification('Your file was successfully uploaded');
          // Update uploadeed status
          fileUploaded(reportType);
          // Refetch gql query after 30 seconds.
          setTimeout(() => {
            dispatch(actions.setReloadManageNodes(true));
          }, 30000);
        } else {
          showAlertDialog('Error', `Failed: ${response.statusText}`);;
        }
      })
      .catch((error) => {
        // handle errors
        setUploading?.(false);
        showAlertDialog('Error', `Failed: ${error.message}`);
      });
  };

  const handleClose = () => {
    setOpenExtraFieldsFile(undefined);
    setOpenAlert(false);
  };

  const bgColor = () => {
    if (isDragAccept) {
      return '#00e67616';
    }
    if (isDragReject) {
      return '#ff174416';
    }
    return '#eeeeee';
  };

  const identifer = `upload-csv-${reportType}`
  return (
    <StyledStack direction='row' justifyContent={'center'} alignItems='center' sx={{ background: bgColor() }}>
      <label {...getRootProps()} htmlFor={identifer}>
        <input
          {...getInputProps()}
          id={identifer}
          accept='.csv'
          type='file'
          multiple={false}
          onChange={changeHandler}
          onClick={event => {
            event.currentTarget.value = '';
          }}
        />
        <Button
          component='span'
          sx={{
            textTransform: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '4px',
          }}>
          <BackupOutlinedIcon sx={{ color: 'black' }} />
          <StyledTypography>Click or drag a file to upload</StyledTypography>
        </Button>
      </label>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {alertTitle}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          {!!openExtraFieldsFile && (
            <Button onClick={handleClose}>CANCEL</Button>
          )}
          <Button onClick={() => {
            if (!!openExtraFieldsFile) {
              setOpenAlert(false);
              startUploadCsvProcess(openExtraFieldsFile);
            } else {
              handleClose();
            }
          }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  border: '1px solid grey',
  borderStyle: 'dashed',
  height: '70px',
});

const StyledTypography = styled(Typography)({
  fontSize: 15,
  fontWeight: 400,
  color: 'black',
});

export const pp_download_csv = gql`
  mutation pp_upload_csv($partnerId: String!, $reportType: DataReportType!) {
    generateUploadFileUrlData(partnerId: $partnerId, reportType: $reportType) {
      signedS3URL
    }
  }
`;
