import { gql } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, forwardRef, useEffect } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { CircularProgress } from '@mui/material';

import {
  PublicPartnerConnectionStatus,
  PublicPartnerFragmentFragment,
  PublicPartnerType,
  usePublicPartnersQuery,
} from 'graphql/types';
import { ConnectExistingButton } from './parts/ConnectExistingButton';
import { exhaustiveCheckNoError } from 'helpers/types';
import { useAppSelector } from 'store/hooks';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';

const LinkBehavior = forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => (
  <Link ref={ref} to="/connect" {...props} role={undefined} />
));

const DEFAULT_DATA: PublicPartnerFragmentFragment[] = [];

export const DashboardView: FC = () => {
  const { user } = useAuth0();
  const { data: publicPartnersResponse, loading } = usePublicPartnersQuery();
  const data = publicPartnersResponse?.publicPartners || DEFAULT_DATA;
  const partnerName = useAppSelector((state) => state.app.partnerName);
  const partnerUuid = useAppSelector((state) => state.app.partnerUUUID);

  const isDuplicatedPartner = data.filter(item => (item.name === partnerName)).length > 1;

  useEffect(() => {
    trackGoogleAnalyticsPageView('/', 'Dashboard');
  }, []);

  const Columns = [
    {
      label: 'Connection Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
        filterList: partnerName ? [partnerName] : [],
      },
    },
    {
      label: 'Connection Type',
      name: 'type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Connection Uuid',
      name: 'reasonPartnerUuid',
      options: {
        filter: true,
        sort: true,
        filterList: partnerUuid && isDuplicatedPartner ? [partnerUuid] : [],
      },
    },
    {
      label: 'Marketplace',
      name: 'marketplace',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Status',
      name: 'Connected',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: number) => {
          if (data.length > dataIndex) {
            const original = data[dataIndex];

            const requiresAdvertisingProfile =
              original.type === PublicPartnerType.Advertising && !original.advertisingProfileId;

            let displayString = '';
            switch (original.connectedStatus) {
              // Authorized, but not connected
              case PublicPartnerConnectionStatus.AuthorizationProgress:
                if (original.type === PublicPartnerType.Advertising && !original.advertisingProfileId) {
                  displayString = 'Select Advertising Profile';
                } else {
                  displayString = 'Onboarding in Progress';
                }
                break;

              // Not authorized or connected
              case PublicPartnerConnectionStatus.BeginAuthorization:
                displayString = 'Authorization Required';
                break;

              case PublicPartnerConnectionStatus.Connected:
                displayString = 'Connected';
                break;

              // Authorization will expire soon
              case PublicPartnerConnectionStatus.NeedsReauthorization:
                displayString = 'Needs re-authorization';
                break;

              // Authorization has expired
              case PublicPartnerConnectionStatus.AuthorizationExpired:
                displayString = 'Authorization expired';
                break;

              default:
                exhaustiveCheckNoError(original.connectedStatus);
                break;
            }

            const connectBtn =
              original.connectedStatus === PublicPartnerConnectionStatus.Connected && !requiresAdvertisingProfile ? (
                <ConnectExistingButton partner={original} isDisable />
              ) : (
                <ConnectExistingButton partner={original} />
              );

            if (!displayString && requiresAdvertisingProfile) {
              displayString = 'Select Advertising Profile';
            }

            return (
              <StyledIconContainer>
                {connectBtn}
                <Typography sx={{ fontSize: 12 }}>{displayString}</Typography>
              </StyledIconContainer>
            );
          }
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
  };
  return (
    <StyledContainer>
      <Typography textAlign={'center'}>Welcome {user?.email}</Typography>
      <Button component={LinkBehavior}>Connect new Account</Button>
      {loading ? (
        <CircularProgress
          size={40}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      ) : (
        <MUIDataTable title={`${partnerName} Connections`} columns={Columns} data={data} options={options} />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const GetPublicPartnersQuery = gql`
  fragment PublicPartnerFragment on PublicPartner {
    advertisingProfileId
    createdAt
    id
    isConnected
    connectedStatus
    marketplace
    name
    type
    reasonPartnerName
    reasonPartnerUuid
  }

  query PublicPartners {
    publicPartners {
      ...PublicPartnerFragment
    }
  }
`;
